import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

type notificationInitialStateType = {
  notificationViewSortedData: any[];
  notificationArr: any[];
  isReaded: boolean;
  isMsgReaded: number;
  numberOfAlerts: number | undefined;
};
const initialState: notificationInitialStateType = {
  notificationViewSortedData: [],
  notificationArr: [],
  isReaded: false,
  isMsgReaded: -1,
  numberOfAlerts: undefined,
};

const notificationSlice = createSlice({
  name: "notification/Details",
  initialState,
  reducers: {
    setNotificationModelSortedData: (state, action) => {
      state.notificationViewSortedData = action.payload;
    },
    updateNotificationModelSortedData: (state, action) => {
      //state.notificationViewSortedData = action.payload;
    },
    setIsReaded: (state, action) => {
      state.isReaded = action.payload;
    },
    setIsMsgReaded: (state, action) => {
      state.isMsgReaded = action.payload;
    },
    setNotification: (state, action) => {
      state.notificationArr.push(action.payload);
    },
    setNoOfAlerts : (state,action) => {
      state.numberOfAlerts = action.payload
    },
    clearNotification: (state) => {
      state.notificationArr = [];
      state.notificationViewSortedData = [];
    },
    notificationSliceSetInitialState: () => initialState,
  },
});

export const {
  setNotificationModelSortedData,
  updateNotificationModelSortedData,
  setNotification,
  clearNotification,
  setIsReaded,
  setIsMsgReaded,
  setNoOfAlerts,
  notificationSliceSetInitialState,
} = notificationSlice.actions;
export default notificationSlice.reducer;
